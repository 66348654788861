/******************************
**
** Container/Gutters
**
******************************/
#page-header:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQInVrEsf.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJzVrEsf.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 400;
  src: local("Hind Vadodara Regular"), local("HindVadodara-Regular"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJLVrA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuW8-oBOL.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXo-oBOL.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 600;
  src: local("Hind Vadodara SemiBold"), local("HindVadodara-SemiBold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXQ-oA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuW8-oBOL.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXo-oBOL.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Hind Vadodara";
  font-style: normal;
  font-weight: 700;
  src: local("Hind Vadodara Bold"), local("HindVadodara-Bold"), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXQ-oA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "icomoon";
  src: url("../../static/fonts/icomoon/fonts/icomoon.eot?a2gchp");
  src: url("../../static/fonts/icomoon/fonts/icomoon.eot?a2gchp#iefix") format("embedded-opentype"), url("../../static/fonts/icomoon/fonts/icomoon.ttf?a2gchp") format("truetype"), url("../../static/fonts/icomoon/fonts/icomoon.woff?a2gchp") format("woff"), url("../../static/fonts/icomoon/fonts/icomoon.svg?a2gchp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i:not(.mce-ico) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-espace-client:before {
  content: "\e972";
}

.icon-compteur:before {
  content: "\e96f";
}

.icon-alert:before {
  content: "\e970";
}

.icon-chart:before {
  content: "\e971";
}

.cooking:before {
  content: "\e963";
}

.window:before {
  content: "\e964";
}

.fire:before {
  content: "\e965";
}

.termostat:before {
  content: "\e966";
}

.shower:before {
  content: "\e967";
}

.water-sink:before {
  content: "\e968";
}

.worker:before {
  content: "\e95b";
}

.heating-water:before {
  content: "\e96a";
}

.air:before {
  content: "\e96b";
}

.temperature-list:before {
  content: "\e96c";
}

.blinds-raised:before {
  content: "\e96d";
}

.window-frame:before {
  content: "\e96e";
}

.icon-worker-2:before {
  content: "\e962";
}

.icon-graduation:before {
  content: "\e960";
}

.icon-handshake:before {
  content: "\e961";
}

.icon-newspaper:before {
  content: "\e959";
}

.icon-hydrogen:before {
  content: "\e957";
}

.icon-recycling:before {
  content: "\e958";
}

.icon-gas-station-full:before {
  content: "\e94f";
}

.icon-big-arrowup:before {
  content: "\e950";
}

.icon-thumbup:before {
  content: "\e951";
}

.icon-big-moins:before {
  content: "\e952";
}

.icon-big-plus:before {
  content: "\e953";
}

.icon-camera:before {
  content: "\e954";
}

.icon-big-euro:before {
  content: "\e955";
}

.icon-heart:before {
  content: "\e956";
}

.icon-param:before {
  content: "\e94e";
}

.icon-pause:before {
  content: "\e94d";
}

.icon-lightbulb1:before {
  content: "\e94b";
}

.icon-tools:before {
  content: "\e94c";
}

.icon-assignment:before {
  content: "\e949";
}

.icon-quote1:before {
  content: "\e94a";
}

.icon-document:before {
  content: "\e946";
}

.icon-pdf:before {
  content: "\e947";
}

.icon-photo:before {
  content: "\e948";
}

.icon-callback:before {
  content: "\e944";
}

.icon-phonecall:before {
  content: "\e945";
}

.icon-quote:before {
  content: "\e943";
}

.icon-gnv:before {
  content: "\e942";
}

.icon-add:before {
  content: "\e900";
}

.icon-addlocation:before {
  content: "\e901";
}

.icon-arrowback:before {
  content: "\e902";
}

.icon-arrowbotom:before {
  content: "\e903";
}

.icon-arrowright:before {
  content: "\e904";
}

.icon-arrowup:before {
  content: "\e905";
}

.icon-avatar:before {
  content: "\e906";
}

.icon-bullet:before {
  content: "\e907";
}

.icon-cards:before {
  content: "\e908";
}

.icon-chat:before {
  content: "\e909";
}

.icon-check:before {
  content: "\e90a";
}

.icon-check-filled:before {
  content: "\e90b";
}

.icon-close:before {
  content: "\e90c";
}

.icon-comments:before {
  content: "\e90d";
}

.icon-creditcard:before {
  content: "\e90e";
}

.icon-date:before {
  content: "\e90f";
}

.icon-date-1:before {
  content: "\e910";
}

.icon-delete:before {
  content: "\e911";
}

.icon-doc:before {
  content: "\e912";
}

.icon-download:before {
  content: "\e913";
}

.icon-edit:before {
  content: "\e914";
}

.icon-electric:before {
  content: "\e915";
}

.icon-electricmeter:before {
  content: "\e916";
}

.icon-euros:before {
  content: "\e917";
}

.icon-eye:before {
  content: "\e918";
}

.icon-eye-blind:before {
  content: "\e919";
}

.icon-facebook:before {
  content: "\e91a";
}

.icon-filter:before {
  content: "\e91b";
}

.icon-home:before {
  content: "\e938";
}

.icon-info:before {
  content: "\e939";
}

.icon-instagram:before {
  content: "\e93a";
}

.icon-leaf:before {
  content: "\e93b";
}

.icon-lightbulb:before {
  content: "\e93c";
}

.icon-link:before {
  content: "\e93d";
}

.icon-linkedin:before {
  content: "\e93e";
}

.icon-list:before {
  content: "\e93f";
}

.icon-mail:before {
  content: "\e940";
}

.icon-menu:before {
  content: "\e941";
}

.icon-miniarrowbottom:before {
  content: "\e91c";
}

.icon-miniarrowleft:before {
  content: "\e91d";
}

.icon-miniarrowright:before {
  content: "\e91e";
}

.icon-miniarrowup:before {
  content: "\e91f";
}

.icon-minicards:before {
  content: "\e920";
}

.icon-minimize:before {
  content: "\e921";
}

.icon-more:before {
  content: "\e922";
}

.icon-mylocation:before {
  content: "\e923";
}

.icon-navigation:before {
  content: "\e924";
}

.icon-partners:before {
  content: "\e925";
}

.icon-phone:before {
  content: "\e926";
}

.icon-pinterest:before {
  content: "\e927";
}

.icon-place:before {
  content: "\e928";
}

.icon-play:before {
  content: "\e929";
}

.icon-plug:before {
  content: "\e92a";
}

.icon-question:before {
  content: "\e92b";
}

.icon-reload:before {
  content: "\e92c";
}

.icon-schedule:before {
  content: "\e92d";
}

.icon-search:before {
  content: "\e92e";
}

.icon-searchfournisseur:before {
  content: "\e92f";
}

.icon-searchinfo:before {
  content: "\e930";
}

.icon-searchinstal:before {
  content: "\e931";
}

.icon-services:before {
  content: "\e932";
}

.icon-share:before {
  content: "\e933";
}

.icon-touch:before {
  content: "\e934";
}

.icon-twitter:before {
  content: "\e935";
}

.icon-warning:before {
  content: "\e936";
}

.icon-work:before {
  content: "\e937";
}

.icon-cycle:before {
  content: "\e95a";
}

.icon-worker:before {
  content: "\e95b";
}

.icon-building:before {
  content: "\e95c";
}

.icon-equality:before {
  content: "\e95d";
}

.icon-feuille:before {
  content: "\e95e";
}

.icon-hard-hat:before {
  content: "\e95f";
}

/* ===================
	    Colors
/* ===================*/
/* ===================
	    Fonts
/* ===================*/
/* ===================
    Config
/* ===================*/
/* ===================
    Dauphine var default
/* ===================*/
/* ===================
    Margin & padding
/* ===================*/
/* ===================
    FlexContent
/* ===================*/
/* ===================
    Animation
/* ===================*/
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

/* ===================
	    Fonts
/* ===================*/
/* ===================
	    Icomoon
/* ===================*/
/* ===================
	    Background
/* ===================*/
/* ===================
	    Layout
/* ===================*/
#page-header {
  min-height: 334px;
  padding-top: 55px;
  padding-bottom: 20px;
  position: relative;
}
@media (min-width: 960px) {
  #page-header {
    padding-top: 138px;
  }
}
#page-header:before {
  content: "";
  z-index: 1;
  background: linear-gradient(16.16deg, #50BDE0 0.6%, #33186A 91.31%);
  bottom: 118px;
}
@media (min-width: 720px) {
  #page-header:before {
    background: linear-gradient(11.31deg, #50BDE0 0.6%, #33186A 91.31%);
    bottom: 245px;
  }
}
@media (min-width: 960px) {
  #page-header:before {
    background: linear-gradient(5.24deg, #50BDE0 0.6%, #33186A 91.31%);
    bottom: 116px;
  }
}
#page-header:after {
  content: "";
  top: 0;
  bottom: 0;
  left: calc(((100% - 720px) / 2) + 36px);
  width: 1px;
  background-color: #C7CBCF;
  position: absolute;
  display: none;
  z-index: 2;
  opacity: 0.45;
}
@media (min-width: 720px) {
  #page-header:after {
    display: block;
  }
}
@media (min-width: 960px) {
  #page-header:after {
    left: calc(((100% - 940px) / 2) + 24px);
  }
}
@media (min-width: 1280px) {
  #page-header:after {
    left: calc(((100% - 1200px) / 2) + 24px);
  }
}
@media (min-width: 1600px) {
  #page-header:after {
    left: calc(((100% - 1400px) / 2) + 24px);
  }
}
#page-header__in {
  position: relative;
  z-index: 2;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 720px) {
  #page-header__in {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 960px) {
  #page-header__in {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 960px) {
  #page-header__in {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }
}
@media (min-width: 960px) and (min-width: 720px) {
  #page-header__in {
    max-width: 720px;
  }
}
@media (min-width: 960px) and (min-width: 960px) {
  #page-header__in {
    max-width: 940px;
  }
}
@media (min-width: 960px) and (min-width: 1280px) {
  #page-header__in {
    max-width: 1200px;
  }
}
@media (min-width: 960px) and (min-width: 1600px) {
  #page-header__in {
    max-width: 1400px;
  }
}
#page-header .page-header__title {
  margin-bottom: 30px;
}
#page-header .page-header__title .headline {
  color: white;
}
@media (min-width: 960px) {
  #page-header .page-header__title {
    margin-bottom: 0;
  }
}
#page-header .page-header__date {
  color: #fff;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
#page-header .page-header__date .icon {
  font-size: 24px;
  color: #fff;
  opacity: 0.6;
  margin-right: 10px;
}
#page-header .page-header__undertitle {
  margin-top: 25px;
}
@media (min-width: 960px) {
  #page-header .page-header__txt, #page-header .page-header__share, #page-header .page-header__img {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
}
@media (min-width: 720px) {
  #page-header .page-header__breadcrumb {
    margin-bottom: 40px;
  }
}
@media (min-width: 720px) {
  #page-header .page-header__txt {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  #page-header .page-header__txt {
    max-width: 720px;
  }
}
@media (min-width: 720px) and (min-width: 960px) {
  #page-header .page-header__txt {
    max-width: 940px;
  }
}
@media (min-width: 720px) and (min-width: 1280px) {
  #page-header .page-header__txt {
    max-width: 1200px;
  }
}
@media (min-width: 720px) and (min-width: 1600px) {
  #page-header .page-header__txt {
    max-width: 1400px;
  }
}
@media (min-width: 720px) {
  #page-header .page-header__txt__in {
    margin-left: 8.3333333333%;
    margin-right: 8.3333333333%;
    padding: 0 12px;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__txt__in {
    margin-left: 0%;
    margin-right: 8.3333333333%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 150px;
    padding-top: 15px;
    height: 100%;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__txt {
    width: 41.6666666667%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 8.3333333333%;
    margin-right: 0%;
    /*display: flex;
    flex-direction: column;

    justify-content: space-between;
    padding-bottom: rem(150);
    padding-top: rem(15);

    @include offset(0);
    @include offset(0, 12, 'right');*/
  }
}
@media (min-width: 720px) {
  #page-header .page-header__share {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  #page-header .page-header__share {
    max-width: 720px;
  }
}
@media (min-width: 720px) and (min-width: 960px) {
  #page-header .page-header__share {
    max-width: 940px;
  }
}
@media (min-width: 720px) and (min-width: 1280px) {
  #page-header .page-header__share {
    max-width: 1200px;
  }
}
@media (min-width: 720px) and (min-width: 1600px) {
  #page-header .page-header__share {
    max-width: 1400px;
  }
}
#page-header .page-header__share__spacer {
  flex: 1;
  height: 1px;
  background-color: #A6B0B9;
  margin-right: 24px;
}
#page-header .page-header__share__in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #616B75;
}
@media (min-width: 720px) {
  #page-header .page-header__share__in {
    margin-left: 8.3333333333%;
    margin-right: 8.3333333333%;
    padding: 0 12px;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__share__in {
    margin-left: 0%;
    margin-right: 8.3333333333%;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__share {
    width: 41.6666666667%;
    padding-left: 0;
    padding-right: 0;
    margin-top: -85px;
    margin-right: 0%;
    margin-left: 8.3333333333%;
    padding-right: 12px;
  }
}
#page-header .page-header__share__list {
  display: flex;
}
#page-header .page-header__share__list .page-header__share__link {
  width: 48px;
  height: 48px;
  border: 1px solid #C7CBCF;
  color: #616B75;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 250ms ease-out;
}
#page-header .page-header__share__list .page-header__share__link .icon {
  font-size: 18px;
}
#page-header .page-header__share__list .page-header__share__link:hover {
  color: #3BC6E5;
  border-color: #3BC6E5;
}
#page-header .page-header__img {
  margin-bottom: 30px;
  /*&-in {
    @include breakpoint(md) {
      margin-right: rem(-120);
    }
  }*/
}
@media (min-width: 720px) {
  #page-header .page-header__img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  #page-header .page-header__img {
    max-width: 720px;
  }
}
@media (min-width: 720px) and (min-width: 960px) {
  #page-header .page-header__img {
    max-width: 940px;
  }
}
@media (min-width: 720px) and (min-width: 1280px) {
  #page-header .page-header__img {
    max-width: 1200px;
  }
}
@media (min-width: 720px) and (min-width: 1600px) {
  #page-header .page-header__img {
    max-width: 1400px;
  }
}
#page-header .page-header__img img {
  width: 100%;
  box-shadow: 2px 12px 30px -5px rgba(28, 26, 54, 0.25);
  object-fit: cover;
  max-height: 195px;
}
@media (min-width: 720px) {
  #page-header .page-header__img img {
    max-height: 420px;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__img img {
    min-height: 400px;
    max-height: 500px;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__img {
    width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 720px) {
  #page-header .page-header__img__in {
    margin-left: 8.3333333333%;
    padding: 0 12px;
    margin-right: -120px;
  }
}
@media (min-width: 960px) {
  #page-header .page-header__img__in {
    margin-left: 0%;
    padding: 0 12px;
  }
}
#page-header .round-picto {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, 50%);
}

#page-header.without-img {
  min-height: 220px;
  overflow: visible;
  margin-bottom: 0;
}
@media (min-width: 720px) {
  #page-header.without-img {
    min-height: 295px;
    padding-top: 35px;
  }
}
@media (min-width: 960px) {
  #page-header.without-img {
    min-height: auto;
    padding-top: 130px;
    max-height: 476px;
  }
}
@media (min-width: 1280px) {
  #page-header.without-img {
    padding-top: 135px;
  }
}
#page-header.without-img:before {
  bottom: 0;
}
#page-header.without-img #page-header__in {
  padding-left: 0;
}
#page-header.without-img .page-header__txt {
  text-align: center;
}
@media (min-width: 960px) {
  #page-header.without-img .page-header__txt {
    margin-left: 8.3333333333%;
    width: 91.6666666667%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 100px;
  }
}
@media (min-width: 720px) {
  #page-header.without-img .page-header__breadcrumb {
    margin-bottom: 55px;
  }
}
@media (min-width: 960px) {
  #page-header.without-img .page-header__breadcrumb {
    margin-bottom: 100px;
    width: 40%;
  }
}
@media (min-width: 960px) {
  #page-header.without-img .page-header__title {
    text-align: center;
  }
}